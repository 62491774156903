import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { MatButtonModule } from "@angular/material/button";
import { MatDialogModule } from "@angular/material/dialog";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatIconModule } from "@angular/material/icon";
import { MatInputModule } from "@angular/material/input";
import { RouterModule } from "@angular/router";
import { LoaderComponent } from "@app/shared/components/roam-loader/loader/loader.component";
import { AuthPopupComponent } from "./auth-popup.component";
import { RegisterComponent } from "./register/register.component";
import { SocialLoginComponent } from "./social-login/social-login.component";
import { MatSelectModule } from "@angular/material/select";
import { MatOptionModule } from "@angular/material/core";
import { LoginComponent } from "./login/login.component";

@NgModule({
  declarations: [
    AuthPopupComponent,
    SocialLoginComponent,
    RegisterComponent,
    LoginComponent,
  ],
  exports: [
    AuthPopupComponent,
    SocialLoginComponent,
    RegisterComponent,
    LoginComponent,
  ],
  imports: [
    CommonModule,
    RouterModule,
    ReactiveFormsModule,
    FormsModule,
    MatDialogModule,
    MatFormFieldModule,
    MatInputModule,
    MatButtonModule,
    MatSelectModule,
    MatOptionModule,
    MatIconModule,
    LoaderComponent,
  ],
})
export class AuthPopupModule {}
