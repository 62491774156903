<mat-drawer-container [hasBackdrop]="layoutSidebarService.hasBackdrop$ | async">
  <mat-drawer
    #drawer
    mode="over"
    position="end"
  >
    @if (layoutSidebarService.sidebarComponent$ | async; as component) {
      <ng-template [cdkPortalOutlet]="component"></ng-template>
    }
  </mat-drawer>

  <mat-drawer-content>
    <router-outlet />
  </mat-drawer-content>
</mat-drawer-container>
