import { inject } from "@angular/core";
import { CanMatchFn } from "@angular/router";
import { RoamAclService } from "@app/core/access-control/+data-access";

/**
 * TODO: remove
 * @deprecated use (app/pages/role/+data-access).canMatchRole("member") instead
 */
export const canMatchRoleVendor: CanMatchFn = () => {
  return inject(RoamAclService).isGranted("member");
};
