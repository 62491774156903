import { Component, ViewChild, inject } from "@angular/core";
import { MatDrawer } from "@angular/material/sidenav";
import { LayoutSidebarService, LogService } from "@shared/services";

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
})
export class AppComponent {
  protected logService = inject(LogService);
  protected layoutSidebarService = inject(LayoutSidebarService);

  @ViewChild("drawer")
  set setDrawer(drawer: MatDrawer) {
    this.layoutSidebarService.drawer = drawer;
  }

  constructor() {
    this.logService.mixpanelInit();
  }
}
