// gpt-command-dialog.component.ts
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatDialogRef } from '@angular/material/dialog';
import { Component, Inject } from '@angular/core'
import { DragDropModule } from '@angular/cdk/drag-drop';
import { GptService } from '@app/shared/services/gpt.service'
import { Subscription } from 'rxjs'
import { iconLib } from "@app/core/const/roam-icon";

@Component({
  selector: 'app-gpt-command-dialog',
  templateUrl: './gpt-command-dialog.component.html',
  styleUrls: ['./gpt-command-dialog.component.scss'],
})
export class GptCommandDialogComponent {
  public command: string = '';
  private subscription: Subscription | undefined;
  private readonly selectedText: string = '';

  constructor(
    public dialogRef: MatDialogRef<GptCommandDialogComponent>,
    private gptService: GptService,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.selectedText = data.selectedText;
  }

  cancel(): void {
    this.dialogRef.close();
  }

  execute(): void {
    const request = this.selectedText + ' ' + this.command;
    this.subscription = this.gptService.askGpt(request).subscribe(response => {
      this.dialogRef.close(response.output);
    });
  }

  ngOnDestroy(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }
}
