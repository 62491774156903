import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from "@angular/common/http";
import { inject, Injectable } from "@angular/core";
import { AuthStore } from "@app/core/auth";
import { Observable, throwError } from "rxjs";
import { catchError } from "rxjs/operators";

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
  #auth = inject(AuthStore);

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      catchError(err => {
        if ([401, 403].includes(err.status) && this.#auth.isAuthenticated()) {
          // auto logout if 401 or 403 response returned from api
          this.#auth.logout();
        }
        const error = err.error?.message || err.statusName;
        console.error(err);
        return throwError(() => error);
      })
    );
  }
}
